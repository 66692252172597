/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.mobile-menu').click(function() {
          $(this).toggleClass('open');
          $('.banner.mobile').toggleClass('open');
          $('body').toggleClass('menu-open');
        });

        WebFont.load({
          typekit: {
            id: 'kay7ycc'
          }
        });

        if ($('#instagram').length) {
          var alibiInsta = new Instafeed({
            get: 'user',
            userId: '3253672159',
            sortBy: 'most-recent',
            limit: '12',
            resolution: 'standard_resolution',
            accessToken: '3253672159.1677ed0.4b7bd3f7694e43bf97d6101f67479597',
            template: '<li class="col-6 col-md-2"><a href="{{link}}" target="_blank"><img src="{{image}}" class="img-fluid"></a></li>',
            target: 'instagram'
          });
          alibiInsta.run();
        }

        $('.main-carousel').flickity({
          // options
          cellAlign: 'left',
          contain: true,
          wrapAround: true,
          imagesLoaded: true,
        });

        /*
         * Video Modals
         */
        var $modals = $('.video-modal');
        var $ambientVideos = $('.video');

        // Pause all ambient videos when modal opened
        $modals.on('show.bs.modal', function(e) {
          $ambientVideos.each(function() {
            var $thisVid = $(this);
            $thisVid.get(0).pause();
          });
        });

        // Remove and replace video embeds to effectively stop the video without need for apis
        $modals.on('hidden.bs.modal', function(e) {
          var $modalIframe = $(this).find('iframe');
          var youTubeEmbed = $modalIframe.clone();

          // Remove Video Embed
          $modalIframe.remove();

          // Replace Video Embed
          $(this).find('.embed-responsive').append(youTubeEmbed);

          // 'Resume' all ambient videos when modal closed
          // TODO: maybe only resume in view videos?
          // modal ID could be used to pause and play the ambient video associated with current modal
          $ambientVideos.each(function() {
            var $thisVid = $(this);
            $thisVid.get(0).play();
          });
        });

        // Smooth Scroll to Anchors //
        $('.anchor').on('click', function(e) {
          e.preventDefault();

          var target = this.hash;
          var $target = $(target);

          $('html, body').stop().animate({
            'scrollTop': $target.offset().top
          }, 900, 'swing', function() {
            window.location.hash = target;
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'event_menus': {
      init: function() {
        // JavaScript to be fired on the about us page
        var isCardsWider = {};

            function checkCardWidths() {
              var $cardCarousels = $('.menu-nav');

              $cardCarousels.each(function(index) {
                var totalWidth = 0;
                var cells = $(this).flickity().data('flickity').cells;

                cells.forEach(function(item) {
                  totalWidth += item.size.outerWidth;
                });

                var nowCardsWider = totalWidth > $(window).width();

                if (nowCardsWider !== isCardsWider[index]) {
                  isCardsWider[index] = nowCardsWider;

                  if (isCardsWider[index]) {
                    // $(this).flickity('bindDrag');
                    $(this).flickity('activatePrevNextButtons');
                    // Select the first card when we're actually using this as a carousel
                    // $(this).flickity('select', 0, false, true);
                  } else {
                    // asNavFor doesn't work without draggable
                    // $(this).flickity('unbindDrag');
                    $(this).flickity('deactivatePrevNextButtons');
                    // $(this).flickity('select', (Math.ceil(cells.length/2) - 1), false, true);
                  }
                }
              });
            }

            var navCarousel = $('.menu-nav').flickity({
              asNavFor: '.menu-browser',
              cellAlign: 'center',
              draggable: true,
              contain: true,
              prevNextButtons: true,
              wrapAround: true,
              pageDots: false
            });

            var menuBrowser = $('.menu-browser').flickity({
              cellAlign: 'left',
              prevNextButtons: false,
              pageDots: false,
              draggable: false,
              freeScroll: false,
              wrapAround: true,
              adaptiveHeight: true,
            });

            // Set up flickity instance
            var menuBrowserFlickity = menuBrowser.data('flickity');

            // Get the current hash from the location
            var menuSlug = window.location.hash.substr(1);

            if ($('[data-id="' + menuSlug + '"]').length) {
              // If we have an element matching that hash, select it
              menuBrowser.flickity('selectCell', '[data-id="' + menuSlug + '"]', null, true);
            } else {
              // Otherwise set the slug to the currently selected slide and set it as the hash
              menuSlug = $(menuBrowserFlickity.selectedElement).attr('data-id');
              window.location.hash = menuSlug;
            }

            menuBrowser.on('select.flickity', function() {
              // When the slide changes, get the ID from the now selected slide and set it as the hash
              menuSlug = $(menuBrowserFlickity.selectedElement).attr('data-id');
              window.location.hash = menuSlug;
            });

            checkCardWidths();

            $(window).on('resize', function() {
              checkCardWidths();
            });

            menuBrowser.find('.flickity-prev-next-button').each(function(){
              var menuDirection = $(this).attr('aria-label');
              menuDirection.replace(/^[a-z]/, function (x) {return x.toUpperCase();});
              $(this).append('<span>'+ menuDirection + ' Menu</span>');
            });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
